@import '../../../scss/variables.scss';

$abstract-background: url('./abstract.svg');
$main-logo: url('./logo.svg');
$main-logo-hor: url('./logo-hor.svg');
$go-dryv-logo: url('./go-dryv-logo.svg');
$abstract-screen-height-xs: 468px;
$form-layout-screen-sm-md: 520px;
$abstract-screen-height-md: 800px;
$screen-md-lg: 1500px;

.form-layout {
	display: -ms-grid;
	display: grid;
	@media (min-width: $screen-md) {
		-ms-grid-columns: 2fr 3fr;
		grid-template-columns: 2fr 3fr;
		min-height: 100vh;
	}

	&__container {
		-ms-grid-row: 1;
		-ms-grid-column: 1;
		margin: 0 auto;
		position: relative;
		min-width: 260px;
		z-index: 2;
		order: 2;

		@media (min-width: $form-layout-screen-sm-md) {
			margin: 192px auto 40px;
		}

		@media (min-width: $screen-md) {
			margin: 136px auto 40px;
			order: 1;
		}

		@media (min-width: $screen-lg) {
			margin: 170px 243px;
		}
	}

	&__content {
		padding: 0 30px 20px;
		border-top: 1px solid transparent;
		background: $primary-color;
		max-width: 360px;

		@media (min-width: $form-layout-screen-sm-md) {
			padding: 0 130px 40px;
			max-width: 520px;
		}

		@media (min-width: $screen-md) {
			margin: 0 auto;
			max-width: 260px;
			padding: 0;
			flex-direction: column;
			height: 100%;
			display: flex;
		}
	}

	&__intro {
		-ms-grid-row: 1;
		-ms-grid-column: 2;
		order: 1;
		min-height: 60px;
		background: #1D2330;
		background-size: 100%;

		@media (min-width: $form-layout-screen-sm-md) {
			background: $abstract-background no-repeat bottom 21px right 12px, radial-gradient(circle farthest-corner at 82% 80%, $gradient-main-color2 0%, $gradient-main-color1 100%);
			background-size: 380px 524px, 100%;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		@media (min-width: $screen-md) {
			position: relative;
			padding: 180px 57px;
			background-size: 380px 524px, 100%;
			order: 2;
		}

		@media (min-width: $screen-md-lg) {
			background-position: bottom 21px right 12px, center;
			background-size: 526px 810px, 100%;
			padding: 180px 57px;
			order: 2;
		}

		@media (min-width: $screen-lg) {
			background-size: 526px 810px, 100%;
			padding: 204px 179px;
		}
	}

	&__footer {
		margin-top: 25px;

		@media (min-width: $screen-md) {
			 margin-top: 70px;
		}
	}

	&__intro-logo {
		width: 198px;
		height: 60px;
		margin: 0 auto 10px;
		background: $main-logo-hor no-repeat;
		background-position: center center;

		@media (min-width: $form-layout-screen-sm-md) {
			margin: 95px auto 12px;
		}

		@media (min-width: $screen-md) {
			width: 220px;
			height: 125px;
			margin: 50px 0 0;
			background-image: $main-logo;
			background-position: left bottom;
		}
	}

	&__intro-text {
		display: none;
		color: $primary-color;

		@media (min-width: $screen-md) {
			display: block;
			width: 438px;
			font-family: 'Oswald', sans-serif;
			font-size: 63px;
			line-height: 79px;
			font-weight: 400;
		}
	}

	&__heading {
		margin: 21px 0 25px;
		line-height: $primary-line-height;
		font-size: 40px;
		font-weight: 300;

		@media (min-width: $form-layout-screen-sm-md) {
			margin: 44px 0;
			font-size: 60px;
		}
	}

	&__error-container {
		margin-top: 10px;
	}

	&__captcha-confirm {
		margin-bottom: 20px;
	}

	&--godryv {
		.form-layout__intro {
			background: $bg-color-go-dryv;
			background-size: 100%;

			@media (min-width: $form-layout-screen-sm-md) {
				background: $bg-color-go-dryv;
				background-size: 380px 524px, 100%;
			}
		}

		.form-layout__footer {
			margin-top: 25px;

			@media (min-width: $screen-md) {
				margin: auto auto 135px;
			}
		}

		.form-layout__intro-logo {
			margin: 10px auto;
			background: $go-dryv-logo no-repeat;
			width: 185px;
			height: 57px;
			background-size: contain;

			@media (min-width: $form-layout-screen-sm-md) {
				margin: 95px auto 12px;
			}

			@media (min-width: $screen-md) {
				margin: 40px 0 0;
				background-image: $go-dryv-logo;
				width: 314px;
				height: 97px;
			}
		}

		.form-layout__intro-text {
			color: $primary-color-go-dryv;
		}
	}
}

.abstract-layout {
	background: $abstract-background no-repeat bottom 7px right 2px, $main-logo no-repeat bottom right 160px;
	background-size: 158px 218px, 125px 52px;

	&__background {
		height: 100vh;
		overflow: hidden;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 20%;
			width: 100%;
			height: 200%;
			top: 0;
			transform: rotate(45deg);
			background: radial-gradient(circle farthest-corner at 70% 70%, $gradient-main-color2 0%, $gradient-main-color1 100%);
			z-index: -1;

			@media (min-width: $screen-xs) and (max-height: $abstract-screen-height-xs) {
				left: 50%;
				top: 30%;
			}

			@media (min-width: $screen-md) {
				left: 46%;
			}

			@media (min-width: $screen-lg) {
				left: 66%;
			}

			@media (min-width: $screen-md-lg) and (max-height: $abstract-screen-height-md) {
				left: 60%;
				top: 30%;
			}
		}
	}

	@media (min-width: $screen-sm) {
		background-size: 373px 515px, 219px 98px;
		background-position: bottom 18px right 2px, bottom right 408px;
	}

	@media (min-width: $screen-sm) and (max-height: $abstract-screen-height-xs) {
		background-size: 158px 218px, 125px 52px;
		background-position: bottom 7px right 2px, bottom right 160px;
	}

	@media (min-width: $screen-md-lg) {
		background-size: 526px 726px, 318px 130px;
		background-position: bottom 24px right 7px, bottom 40px right 551px;
	}

	@media (min-width: $screen-md-lg) and (max-height: $abstract-screen-height-md) {
		background-size: 373px 515px, 219px 98px;
		background-position: bottom 18px right 2px, bottom right 408px;
	}

	&__content {
		max-width: 350px;
		margin: 85px auto 0;
		padding: 0 15px;
		position: relative;

		@media (min-width: $screen-sm) {
			margin: 85px auto;
		}

		@media (min-width: $screen-xs) and (max-height: $abstract-screen-height-xs) {
			margin: 85px 31px 0;
		}

		@media (min-width: $screen-md) {
			max-width: 460px;
			margin: 265px 103px;
			padding: 0;
		}

		@media (min-width: $screen-lg) {
			margin: 371px 429px;
		}
	}

	&__heading {
		padding: 0 5px 45px 0;
		font-size: 25px;
		line-height: 1.3;
		font-weight: 400;
		text-align: center;
		word-break: break-word;

		@media (min-width: $screen-md) {
			text-align: left;
		}
	}

	&__button-container {
		@media (min-width: $screen-md) {
			width: 66%;
		}
	}

	&__link-container {
		margin: 15px 0 20px;
		font-size: 14px;
		text-align: center;

		@media (min-width: $screen-md) {
			margin: 0;
			align-self: center;
		}
	}

	&__action-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		align-items: center;

		@media (min-width: $screen-md) {
			flex-flow: row-reverse;
			justify-content: space-between;
		}
	}
}
