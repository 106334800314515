@import '../../../scss/variables.scss';

$error-background-picture: url('./error-illustration.svg');
$main-logo-black: url('./logo-black.svg');

.error-page-layout {
	position: relative;
	padding: 100px 30px 30px;
	font-size: $primary-font-size;
	line-height: 1;
	overflow: hidden;

	@media (min-width: $screen-sm) {
		max-width: 460px;
		margin: 0 auto;
		padding: 160px 30px 30px;
	}

	@media (min-width: $screen-md) {
		max-width: 580px;
		padding: 370px 30px 30px;
	}

	&__heading {
		margin-bottom: 10px;
		font-size: 30px;
		font-weight: 500;

		@media (min-width: $screen-sm) {
			font-size: 40px;
		}
	}

	&__content {
		margin-bottom: 20px;
		font-size: 30px;
		font-weight: 300;
		line-height: 1.25;

		@media (min-width: $screen-sm) {
			font-size: 40px;
		}
	}

	&__description {
		margin-bottom: 40px;
		line-height: 26px;
	}

	&__logo {
		height: 64px;
		background: $main-logo-black no-repeat;
	}

	&__picture {
		position: absolute;
		top: -140px;
		left: 25%;
		width: 380px;
		height: 300px;
		overflow: hidden;
		background: $error-background-picture no-repeat center center;
		background-size: contain;

		@media (min-width: $screen-sm) {
			top: -25%;
			left: 0;
			right: 0;
			width: 380px;
			height: 330px;
			margin: 0 auto;
		}

		@media (min-width: $screen-md) {
			top: 15px;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
			height: 330px;
		}
	}
}
